import React from "react";
import classNames from "classnames";
import { Tooltip } from "react-tooltip";

export default ({
  label,
  wrapperClassName,
  value,
  onChange,
  name,
  id,
  placeholder,
  ...rest
}) => {
  const tooltipSvgUrl =
    window.js_translations.current_language === "de"
      ? "/fileadmin/Infobase_Grafik_final.svg"
      : "/fileadmin/Infobase_Grafik2_EN.SVG";
  return (
    <div
      className={classNames("form-group position-relative", wrapperClassName)}
    >
      {label && (
        <label htmlFor={name}>
          <div className="font-weight-bold">{label}</div>
        </label>
      )}
      <div className="input-group">
        <input
          type="text"
          className="form-control has-addon"
          onChange={onChange}
          value={value}
          name={name}
          placeholder={placeholder}
          id={id}
          {...rest}
        />
        <div
          className="input-group-append align-items-center pl-1"
          data-tooltip-id="registerTip"
          data-tooltip-position-strategy="fixed"
          data-tooltip-delay-show="50"
          data-tooltip-delay-hide="50"
        >
          <div className="input-group-icon">
            <svg
              className="d-xs-none"
              xmlns="http://www.w3.org/2000/svg"
              width="16.054"
              height="16.054"
              viewBox="0 0 16.054 16.054"
            >
              <path
                d="M11.4,3.375A8.027,8.027,0,1,0,19.429,11.4,8.026,8.026,0,0,0,11.4,3.375Zm.733,11.732H10.661V9.546h1.474ZM11.4,8.94a.77.77,0,1,1,.8-.772A.774.774,0,0,1,11.4,8.94Z"
                transform="translate(-3.375 -3.375)"
                fill="#b5bd00"
              />
            </svg>
            <Tooltip id="registerTip" arrowColor="black" variant="dark">
              <img src={tooltipSvgUrl} alt="" width="450px" height="auto" />
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};
